export const CLIENTS = [
  {
    name: 'subway',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership' },
          { name: 'communication main nz' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'update member details generic' },
        ],
      },
      {
        module: 'subsquad',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'GB', currency: 'GBP' }],
  },
  {
    name: 'columbuscoffee',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'update member details' },
          { name: 'icash topup' },
          { name: 'icash topup reversal' },
          { name: 'icash redemption' },
          { name: 'icash redemption reversal' },  
          { name: 'icash donation' },  
          { name: 'icash donation refund' },  
        ],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'NZ', currency: 'NZD' }],
  },
  {
    name: 'oporto',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication main nz' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'update member details generic' },
          { name: 'icash topup' },
          { name: 'icash topup reversal' },
          { name: 'icash redemption' },
          { name: 'icash redemption reversal' },    
          { name: 'icash donation' }, 
          { name: 'icash donation refund' },   
        ],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'AU', currency: 'AUD' }],
  },
  {
    name: 'redrooster',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication main nz' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'update member details generic' },
          { name: 'icash topup' },
          { name: 'icash topup reversal' },
          { name: 'icash redemption' },
          { name: 'icash redemption reversal' },  
          { name: 'icash donation' },   
          { name: 'icash donation refund' },   
        ],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'AU', currency: 'AUD' }],
  },
  {
    name: 'chickentreat',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication main nz' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'update member details generic' },
          { name: 'icash topup' },
          { name: 'icash topup reversal' },
          { name: 'icash redemption' },
          { name: 'icash redemption reversal' },    
          { name: 'icash donation' },  
          { name: 'icash donation refund' },  
        ],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'AU', currency: 'AUD' }],
  },
  {
    name: 'hampsta',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'identification' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication' },
          { name: 'update member details generic' },
          { name: 'purchase' },
          { name: 'purchase reversal' },
          { name: 'loyalty redemption' },
          { name: 'loyalty redemption reversal' },
          { name: 'loyalty allocation' },
          { name: 'loyalty allocation reversal' },    
          { name: 'icash donation' },  
          { name: 'icash donation refund' },  
        ],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'NZ', currency: 'NZD' }],
  },
  {
    name: 'generic',
    type: 'generic',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'voucher' },
          { name: 'offer' },
          { name: 'identification' },
          { name: 'identification hampsta' },
          { name: 'membership subway' },
          { name: 'communication' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'purchase' },
          { name: 'purchase reversal' },
          { name: 'loyalty redemption' },
          { name: 'loyalty redemption reversal' },
          { name: 'loyalty allocation' },
          { name: 'loyalty allocation reversal' },
          { name: 'update member details' },
          { name: 'icash donation' },  
          { name: 'icash donation refund' },  
        ],
      },
      {
        module: 'merchant',
        subs: [{ name: '*' }],
        exclude: [{ name: null }],
      },
    ],
    misc: [{ countryCode: 'NZ', currency: 'NZD' }],
  },
  {
    name: 'paperplus',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'offer' },
          { name: 'bank accounts' },
          { name: 'direct payments' },    
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication' },
          { name: 'update member details' },
          { name: 'icash topup' },
          { name: 'icash topup reversal' },
          { name: 'icash redemption' },
          { name: 'icash redemption reversal' },   
          { name: 'icash donation' },  
          { name: 'icash donation refund' },   
        ],
      },
    ],
    misc: [{ countryCode: 'NZ', currency: 'NZD' }],
  },
  {
    name: 'countdown',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'offer' },
          { name: 'bank accounts' },
          { name: 'direct payments' },    
          { name: 'identification hampsta' },
          { name: 'identification generic' },
          { name: 'membership subway' },
          { name: 'communication' },
          { name: 'update member details generic' },
          { name: 'purchase' },
          { name: 'purchase reversal' },
          { name: 'loyalty redemption' },
          { name: 'loyalty redemption reversal' },
          { name: 'loyalty allocation' },
          { name: 'loyalty allocation reversal' },
          { name: 'icash donation' },  
          { name: 'icash donation refund' },  
        ],
      },
    ],
    misc: [{ countryCode: 'NZ', currency: 'NZD' }],
  },
  {
    name: 'westfield',
    type: 'loyalty',
    setup: [
      {
        module: 'member',
        subs: [{ name: '*' }],
        exclude: [
          { name: 'voucher' },
          { name: 'offer' },
          { name: 'identification' },
          { name: 'identification hampsta' },
          { name: 'membership subway' },
          { name: 'communication' },
          { name: 'bank accounts' },
          { name: 'direct payments' },
          { name: 'purchase' },
          { name: 'purchase reversal' },
          { name: 'loyalty redemption' },
          { name: 'loyalty redemption reversal' },
          { name: 'loyalty allocation' },
          { name: 'loyalty allocation reversal' },
          { name: 'update member details' },
          { name: 'icash donation' },  
          { name: 'icash donation refund' },  
        ],
      },
    ],
    misc: [{ countryCode: 'NZ', currency: 'NZD' }],
  },
]
